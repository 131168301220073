import React from "react";

import { Footer } from "./styles";

const ModuleFooter = () => (
  <Footer>
    <p>© Copyright 2023 - Todos os Direitos Reservados</p>
  </Footer>
);

export default ModuleFooter;
